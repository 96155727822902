import Swiper from "swiper/bundle";

/* Add double window open on cta click */
function set_onclick_button_redirect_open_windows() {
  const openCTA = document.querySelectorAll(".js--open-cta");

  openCTA.forEach((btn) => {
    btn.addEventListener("click", function () {
      window.open(
        window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          "?show-coupon",
      );
      window.location.href = this.getAttribute("data-url");
    });
  });
}

function set_copy_button() {
  let copyBtns = document.querySelectorAll(".js-copy-btn");

  copyBtns.forEach((copyBtn) => {
    copyBtn.addEventListener("click", function () {
      let copyValue = document.querySelector(".copyValue");

      navigator.clipboard.writeText(copyValue.value);
      copyBtn.textContent = this.getAttribute("data-copied-text");
      copyBtn.style.backgroundColor = "white";
      copyBtn.style.color = "var(--primarycolorsingle)";
      copyBtn.style.border = "2px solid var(--primarycolorsingle)";
      /*
      setTimeout(function() {
        copyBtn.style.backgroundColor = 'var(--primarycolorsingle)'
        copyBtn.style.color = 'white'
        copyBtn.style.border = '2px solid var(--primarycolorsingle)'
      }, 1000)
       */
    });
  });
}

export default {
  init() {
    // JavaScript to be fired on all pages
    const items = document.querySelectorAll(
      ".tips-section__tabs--main, .tips-section__tabs--second, .football-container-news-block-content",
    );
    items.forEach((item) => {
      const url = item.getElementsByTagName("a");
      if (url[0]) {
        item.addEventListener("click", function () {
          window.location = url[0].href;
        });
      }
    });

    set_copy_button();

    let selectTabs = document.querySelectorAll("li[data-target]");

    selectTabs.forEach((tab) => {
      tab.addEventListener("click", function () {
        let dataTarget = this.getAttribute("data-target");
        let dataTabs = document.querySelector(
          '.fw-tabs__panel[data-tabs="' + dataTarget + '"]'
        );

        let activeButtons = document.querySelectorAll("li[data-target].active");

        if (document.querySelector(".fw-tabs__panel.showed")) {
          document
            .querySelector(".fw-tabs__panel.showed")
            .classList.remove("showed");
        }

        activeButtons.forEach((activeButton) => {
          activeButton.classList.remove("active");
        });

        this.classList.add("active");

        document.querySelector(".news-check").checked = false;

        dataTabs.classList.add("showed");
      });
    });

    let fwTabs = document.querySelectorAll(".fw-tabs");

    fwTabs.forEach((tab) => {
      let tabsButtons = document.querySelectorAll(
        ".fw-tabs .fw-tabs__nav .nav-link[data-target]"
      );

      tabsButtons.forEach((button) => {
        button.addEventListener("click", function () {
          let dataTarget = this.getAttribute("data-target");
          let dataTabs = document.querySelector(
            '.fw-tabs__panel[data-tabs="' + dataTarget + '"]'
          );

          let activeButtons = document.querySelectorAll(
            ".fw-tabs__nav span[data-target].active"
          );

          if (document.querySelector(".fw-tabs__panel.showed")) {
            document
              .querySelector(".fw-tabs__panel.showed")
              .classList.remove("showed");
          }

          activeButtons.forEach((activeButton) => {
            activeButton.classList.remove("active");
          });

          this.classList.add("active");

          dataTabs.classList.add("showed");
        });
      });
    });

    // function initAcc(elem, option){
    //   //addEventListener on mouse click
    //   document.addEventListener('click', function (e) {
    //     //check is the right element clicked
    //     if (!e.target.matches(elem+' .accordion-button')) {
    //       return;
    //     } else {
    //       //check if element contains active class
    //       if(!e.target.parentElement.classList.contains('show')){
    //         if(option==true){
    //           //if option true remove show class from all other accordions
    //           var elementList = document.querySelectorAll(elem +' .a-panel');
    //           Array.prototype.forEach.call(elementList, function (e) {
    //             e.classList.remove('show');
    //           });
    //         }
    //         //add show class on cliked accordion
    //         e.target.parentElement.classList.add('show');
    //       } else {
    //         //remove show class on cliked accordion
    //         e.target.parentElement.classList.remove('show');
    //       }
    //     }
    //   })
    // }
    //
    // initAcc('.accordion', true);

    var init = false;

    const toggleAcc = document.querySelectorAll(".accordion-button");

    toggleAcc.forEach((trig) => {
      trig.addEventListener("click", function (e) {
        const target = this.getAttribute("data-bs-target");

        const targetPanel = document.getElementById(target);

        targetPanel.classList.toggle("collapse");

        if (!e.target.parentElement.classList.contains("show")) {
          var elementList = document.querySelectorAll(".accordion .a-panel");

          Array.prototype.forEach.call(elementList, function (e) {
            e.classList.remove("show");
          });

          e.target.parentElement.classList.add("show");
        } else {
          e.target.parentElement.classList.remove("show");
        }

        e.preventDefault();
      });
    });

    let seeMore = document.querySelectorAll(".js-more-tips");

    seeMore.forEach((moore) =>
      moore.addEventListener("click", (e) => {
        const tgt = moore.closest(".tips-list");
        tgt.classList.toggle("show");
        e.preventDefault();
      }),
    );

    function swiperDisable() {
      if (window.outerWidth <= 1023) {
        if (init) {
          Swiper.destroy();
          init = false;
        }
      } else if (!init) {
        init = true;
        new Swiper(".swiper-bookmaker", {
          navigation: {
            nextEl: ".swiper-bookmaker-nav__next",
            prevEl: ".swiper-bookmaker-nav__prev",
          },
          slidesPerView: false,
          breakpoints: {
            1022: {
              slidesPerView: "auto",
              mousewheel: {
                releaseOnEdges: true,
              },
              spaceBetween: 20,
            },
          },
        });
      }
    }
    swiperDisable();
    window.addEventListener("resize", swiperDisable);

    new Swiper(".swiper-game", {
      navigation: {
        nextEl: ".swiper-game-button-next",
        prevEl: ".swiper-game-button-prev",
      },
      slidesPerView: "auto",
      mousewheel: {
        releaseOnEdges: true,
      },
      spaceBetween: 20,
      freeMode: true,
    });

    document.addEventListener("DOMContentLoaded", function () {
      new Swiper(".swiper-odds-nav", {
        spaceBetween: 5,
        initialSlide: 3,
        slidesPerView: "auto",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        freeMode: true,
      });
    });

    document.addEventListener("DOMContentLoaded", function () {
      new Swiper(".swiper-odds-po", {
        spaceBetween: 5,
        initialSlide: 3,
        slidesPerView: "auto",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        freeMode: true,
      });
    });

    let mores = document.querySelectorAll(".more-mobile");

    mores.forEach((more) => {
      more.addEventListener("click", function () {
        if (more.closest(".football-odds-component-block-compare.open")) {
          more
            .closest(".football-odds-component-block-compare.open")
            .classList.remove("open");
          more.querySelector(".see").innerHTML = "More";
        } else {
          more
            .closest(".football-odds-component-block-compare")
            .classList.add("open");
          more.querySelector(".see").innerHTML = "Close";
        }
      });
    });

    $(document).on("click", "form button[type=submit]", function (e) {
      const inputSearch = $(this).parents("form").find("input[name=s]");
      if (inputSearch.val() === "") {
        inputSearch.focus();
        e.preventDefault();
      }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};

document.addEventListener("DOMContentLoaded", function () {
  let buttonToTop = document.querySelector(".to-top");

  if (buttonToTop) {
    buttonToTop.addEventListener("click", function () {
      document.documentElement.scrollTop = 0;
    });
  }

  window.addEventListener("scroll", function () {
    if (window.scrollY >= 400) {
      buttonToTop.classList.add("scroll");
    } else {
      buttonToTop.classList.remove("scroll");
    }
  });

  let bookmakerCta = document.querySelector(".bonus-content");

  if (bookmakerCta !== null && bookmakerCta !== undefined) {
    let bookmakerClone = bookmakerCta.cloneNode(true);

    if (screen.width < 730 && bookmakerCta) {
      window.addEventListener("scroll", function () {
        if (window.scrollY >= 400) {
          if (document.querySelectorAll(".bonus-content.bottom").length < 1) {
            document.body.appendChild(bookmakerClone).classList.add("bottom");

            set_onclick_button_redirect_open_windows();
            set_copy_button();
          }
          if (window.scrollY >= 400 + bookmakerCta.clientHeight)
            bookmakerClone.classList.add("scroll");
        } else {
          bookmakerClone.classList.remove("scroll");
        }
      });
    }
  }

  /*
    if(screen.width < 730 && bookmakerCta) {
    document.body.appendChild(bookmakerClone).classList.add('bottom')
    window.addEventListener('scroll', function () {
      if(window.scrollY >= 400 + bookmakerCta.clientHeight) {
        bookmakerClone.classList.add('scroll')
      } else {
        bookmakerClone.classList.remove('scroll')
      }
    })
  }
  */

  /*

  let sticky = document.querySelector('.bookmaker-cta');

  if(sticky) {
    window.addEventListener('scroll', function () {
        if (window.scrollY >= 400 + sticky.clientHeight) {
            sticky.classList.add('scroll')
            let show = document.querySelectorAll('.on-sticky-show');
            if (show) {
              show.style.display = 'block';
            }
            let hide = document.querySelectorAll('.on-sticky-hide');
            if (hide) {
              hide.style.display = 'none';
            }
        } else {
            sticky.classList.remove('scroll')
            document.querySelectorAll('.on-sticky-show').style.display = 'none';
            document.querySelectorAll('.on-sticky-hide').style.display = 'block';
        }
    })
  }*/

  set_onclick_button_redirect_open_windows();

  let closeOverlay = document.querySelector(
    ".show-overlay .inner .close-container .close",
  );
  if (closeOverlay) {
    closeOverlay.addEventListener("click", function () {
      let overlayShow = this.closest(".show-overlay");
      overlayShow.remove();
    });
  }

  let nav = document.getElementById("main-nav");
  let navToggle = document.getElementById("js-burger");

  navToggle.addEventListener("click", function (e) {
    e.preventDefault();
    nav.classList.toggle("opened");
    this.classList.toggle("active");
  });

  let menuItems = document.querySelectorAll(".main-menu a");

  const currentUrl = window.location.href;

  menuItems.forEach((item) => {
    if (item.href === currentUrl) {
      item.parentElement.closest(".nav-item").classList.add("active");
    }
  });

  const hrsBonus = document.querySelectorAll(".seo-content h2");

  hrsBonus.forEach((heading, index) => {
    const counter = index + 1;
    let headingText = heading.textContent;

    headingText = headingText.replace(/faq/i, "").trim();

    if (!headingText) {
      return;
    }

    let headingLink = headingText.replace(/[^A-Za-z0-9 ]/g, "");
    headingLink = headingLink.replace(/\s+/g, "-").toLowerCase().trim();

    if (/[А-Яа-яЁё]/u.test(headingText)) {
      headingLink = "i-" + counter;
    }

    heading.id = headingLink;
  });

  const tocLinks = document.querySelectorAll(".resume ul li a");

  tocLinks.forEach((tocLink) => {
    tocLink.addEventListener("click", () => {
      tocLinks.forEach((link) => link.classList.remove("fw-bold"));
      tocLink.classList.add("fw-bold");
    });
  });

  const sections = document.querySelectorAll(".content h2");
  const navLinks = document.querySelectorAll(".resume ul li a");

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.6,
  };

  const observer = new IntersectionObserver(function (entries, observer) {
    entries.forEach((entry) => {
      navLinks.forEach((link) => {
        if (link.getAttribute("href").substring(1) === entry.target.id) {
          if (entry.isIntersecting) {
            link.classList.add("fw-bold");
          } else {
            link.classList.remove("fw-bold");
          }
        }
      });
    });
  }, options);

  sections.forEach((section) => {
    observer.observe(section);
  });
});

let toggles = document.querySelectorAll(".first-menu");

toggles.forEach((toggle) => {
  window.addEventListener(
    "resize",
    function () {
      if (window.matchMedia("(min-width: 1200px)").matches) {
        toggle.checked = false;
      }
    },
    true,
  );
});

const promotionsItems = document.querySelectorAll(".promotions-block button");

function togglePromotion() {
  const itemToggle = this.getAttribute("aria-expanded");

  for (let i = 0; i < promotionsItems.length; i++) {
    promotionsItems[i].setAttribute("aria-expanded", "false");
  }

  if (itemToggle == "false") {
    this.setAttribute("aria-expanded", "true");
  }
}

promotionsItems.forEach((item) =>
  item.addEventListener("click", togglePromotion),
);

document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector(".sticky-top")) {
    const toc = document.querySelector(".toc-desktop");
    let tocMobileAdded = false;
    const tocCloned = toc.cloneNode(true);

    if (window.outerWidth <= 1000) {
      document.querySelector(".toc-desktop").remove();
      if (!tocMobileAdded) {
        tocCloned.className += " toc-mobile my-3";
        document
          .querySelector(".football-wrap")
          .insertBefore(tocCloned, document.querySelector(".main > .row"));
        tocMobileAdded = true;
      }
    }

    window.addEventListener("resize", () => {
      if (window.outerWidth <= 1000) {
        if (!tocMobileAdded) {
          toc.remove();
          const tocCloned = toc.cloneNode(true);
          tocCloned.className += " toc-mobile my-3";
          document
            .querySelector(".football-wrap")
            .insertBefore(tocCloned, document.querySelector(".main > .row"));
          tocMobileAdded = true;
        }
      }
      if (window.outerWidth >= 1000 && tocMobileAdded) {
        document.querySelector(".toc-mobile").remove();
        document.querySelector(".sticky-top").appendChild(tocCloned);
        tocMobileAdded = false;
      }
    });

    new Swiper(".swiper-info-reviews", {
      slidesPerView: 3,
      spaceBetween: 8,
      freeMode: true,
    });
  }

  const details = document.querySelectorAll(".bonus_list .details");

  details.forEach((detail) => {
    if (detail.offsetHeight >= 120) {
      const seeMore = document.createElement("div");
      const seeMoreContent = `
          See more
          <span class="more-less"></span>
      `;

      seeMore.innerHTML = seeMoreContent;

      seeMore.className = "see-more my-2 fw-bold text-decoration-underline";

      detail.parentElement.after(seeMore);

      seeMore.addEventListener("click", () => {
        if (seeMore.classList.contains("showed")) {
          seeMore.classList.remove("showed");
          detail.parentElement.style.maxHeight = "120px";
        } else {
          seeMore.classList.add("showed");
          detail.parentElement.style.maxHeight = `${detail.offsetHeight}px`;
        }
      });
    }
  });
});
